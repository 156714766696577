.card {
    background-color: #EBF0EF;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 1.25rem;
}
.cardWrapper {
    padding: 1rem 1.75rem;
}
.cardHeader, .cardFooter {
    text-align: center;
}

.cardHeader img {
    margin-bottom: 1rem;
    max-height: 150px;
}

.cardHeader p {
    margin: 0 0 1rem 0;
    font-weight: 700;
    color: #063A2E;
}

.cardContentItem {
    display: flex;
    margin-bottom: 1.75rem;
}

.cardFooter {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.cardFooter a {
    color: #063A2E !important;
}

.cardContentItem svg {
    margin-right: 0.625rem;
}

.cardContentItem p {
    margin: 0;
}

.cardContent {
    margin-bottom: 1.75rem;
}

@media (min-width: 768px) {
    .card {
        width: 49%;
    }
    .cardContent {
        margin-bottom: 4rem;
    }
    .cardFooter {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}