.table {
    width: 100%;
    text-align: left;
}
.table td .table th {
    padding: 10px 5px;
}
td, th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 10px 5px;
}
@media (max-width: 640px) {
    .colLeft {
        height: 60vh;
    }
}