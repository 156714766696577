
.mailingInput {
    font-weight: 400 !important;
    margin-bottom: 1.25rem;
}

.error {
    color: red;
    margin-top: 16px
}

@media (min-width: 768px) {
    .mailingForm {
        width: 80%;
    }
}