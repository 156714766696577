.h1 {
    color: #063A2E; font-size: 28px; font-weight: 700;
}

.colLeft {
    background-color: #063A2E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.colLeft p {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 50%;
    text-align: center;
    margin-top: 96px;
}
@media (max-width: 640px) {
    .colLeft {
        height: 60vh;
    }
}