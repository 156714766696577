.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;

    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999999999;
}

.modalMain {
    position:fixed;
    background: white;
    height: auto;
    max-width: 750px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 2.5rem;
}

.modalMain p {
    margin-top: 0;
}

.heading {
    margin-bottom: 0 !important;
}

.modalMain form {
    width: 100%;
}

.button {
    padding: 0.75rem 2rem;
    color: #fff;
    max-width: 13rem;
    cursor: pointer;
    background: #063A2E;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.125rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border: solid 2px #063A2E;
}

.button:hover {
    background: #fff;
    border: solid 2px #063A2E;
    color: #063A2E !important;
}

.close {
    color: #063A2E;
    font-weight: 700;
    border-radius: 10px;
    border: 1px #063A2E solid;
    padding: 0.625rem 1.25rem;
    background-color: #fff;
    font-size: 16px;
    cursor: pointer !important;
}

.header {
    vertical-align: middle;
    justify-content: space-between;
    margin-bottom: 1.75rem;
}

.readOnly {
    background-color: #f1f1f1;
}

@media (min-width: 768px) {
    .mailingForm {
        width: 80%;
    }
}
@media (max-width: 980px) {
    .modalMain {
        max-width: 80%;
        width: 80%;
    }
}