.mailingInput {
    font-weight: 400 !important;
    margin-bottom: 1.25rem;
}
.mailingForm img {
    margin-right: 1rem;
}
.error {
    color: red;
    margin-top: 16px
}
.gbpButton {
    color: #063A2E;
    font-weight: 700;
    border-radius: 10px;
    border: 1px #063A2E solid;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    background-color: #fff;
    font-size: 1rem;
    margin-top: 1rem;
}
.mailingForm {
    width: 80%;
}
@media (max-width: 980px) {
    .mailingForm {
        width: auto;
    }
}