.col:nth-child(5n + 1),
.col:nth-child(5n + 2),
.col:nth-child(5n + 3),
.col:nth-child(5n + 4),
.col:nth-child(5n + 5),
.col:nth-child(5n + 6){
    grid-column: span 4;
}

.col {
    background-color: #EBF0EF;
    border-radius: 10px;
    padding: 1rem;
    border: #063A2E 1px solid;
}

.date {
    margin-right: 1rem;
    width: 30%;
}

.date p {
    margin: 0;
}

.month {
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
}

.day {
    color: #063A2E;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
}

.title {
    color: #063A2E;
    font-size: 0.875rem;
    font-weight: 900;
}

.location {
    color: #737373;
    font-size: 0.75rem;
    font-weight: 400;
}

.footer button {
    color: #fff;
    font-weight: 700;
    border: 2px #063A2E solid;
    padding: 0.625rem 1.25rem;
    background-color: #063A2E;
    cursor: pointer;
    border-radius: 0.125rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-size: 1rem;
}

.footer button:hover {
    color: #063A2E;
    background-color: #fff;
}

.footer span {
    display: flex;
    justify-content: center;
}

.footer p {
    font-size: 0.875rem;
}

.participantButton {
    color: #063A2E;
}