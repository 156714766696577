.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
}

.headerTownCrier {
    z-index: 50;
    background-color: #063A2E;
    color: #fff;
    font-weight: 600;
    width: 20%;
    height: auto;
}

.headerLogo {
    display: flex;
    margin-top: 2.25rem;
}

.navItems {
    display: none;
}

.navLink {
    padding: 1rem 1rem;
    cursor: pointer;
    transition-duration: 200ms;
    list-style: none;
}

.navLink:hover {
    transform: scale(1.05);
}

.navHamburger {
    cursor: pointer;
    z-index: 10;
    color: #fff;
}

.navItemsMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #063A2E;
    z-index: 9;
    padding: 0 2.5rem;
}

.navLinkMobile {
    padding: 1.5rem 1rem;
    cursor: pointer;
    font-size: 2.25rem;
    line-height: 2.5rem;
    list-style: none;
    text-align: center;
}

@media (min-width: 768px) {
    .navItems {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 120px;
        min-height: 64.9vh;
    }

    .navHamburger {
        display: none;
    }
}
@media (max-width: 980px) {
    .headerTownCrier {
        width: 100%;
        position: fixed;
    }
    .navbar {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        padding: 10px 20px;
        width: auto;
    }
    .navItems {
        display: none;
    }
    .headerLogo {
        margin: 0;
    }
    .navHamburger {
        display: block;
    }
    .navItemsMobile {
        width: 90%;
        margin-top: 0;
    }
}
@media (max-width: 640px) {
    .navLinkMobile {
        font-size: 1.5rem;
        word-break: break-word;
    }
    .navItemsMobile {
        width: 81%;
    }
    .navLinkMobile svg {
        margin-left: 10px;
    }
}