@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap');
/* Global CSS */
::before,
::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: #e5e7eb; /* 2 */
}

::before,
::after {
    --tw-content: '';
}

html,
:host {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    -o-tab-size: 4;
    tab-size: 4; /* 3 */
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
    font-feature-settings: normal; /* 5 */
    font-variation-settings: normal; /* 6 */
    -webkit-tap-highlight-color: transparent; /* 7 */
}

img,
video {
    max-width: 100%;
    height: auto;
}

body {
    margin: 0;
    line-height: inherit;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, button {
    font-family: inherit;
}

input:focus-visible, select:focus-visible {
    outline: none;
}


a {
    text-decoration: none;
    color: #fff;
}

footer p {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

/* Global CSS */

.header {
    z-index: 50;
    background-color: #fff;
    padding: 1rem;
    color: #fff;
    font-weight: 600;
}

.towncrier-container {
    display: flex;
}

.towncrier-content {
    width: 80%;
}

.section {
    padding: 4rem 2rem;
}

.section-pp {
    padding: 2rem 2rem;
}

.privacy-policy a, .privacy-policy span {
    color: #063A2E !important;
    font-weight: bold;
}

.bg-light-blue {
    background-color: #E9EDEF;
}

.bg-light-green {
    background-color: #EBF0EF;
}

.bg-green {
    background-color: #063A2E;
}

.section-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-col-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.section-img {
    padding-bottom: 4rem;
}

.align-center {
    text-align: center;
}

.structure-subtitle {
    margin-bottom: 1.5rem;
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #063A2E;
}

.search-container {
    display: flex;
    margin-bottom: 4rem;
    justify-content: space-between;
}

.search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-input {
    border: solid 1px #1D4959;
    border-radius: 0.75rem;
    font-weight: bold;
    color: #737373;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem 1.25rem;
    width: 100%;
    box-sizing: border-box;
}

.search-input input {
    border: none;
    font-weight: bold;
    color: #737373;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0;
    width: 100%;
}

.search-input select {
    border: none;
    font-weight: bold;
    color: #737373;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0;
    width: 100%;
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.location-svg svg {
    width: 40px;
}

.short-desc-svg svg {
    width: 50px;
}

.membership-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 6rem;
}

.item-number {
    color: #1D4959;
    font-size: 2.5rem;
    font-weight: 900;
}

.news img {
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.news a, .news {
    color: #000;
    font-weight: 700;
}

.news hr, .latest-news hr {
    border: 1px black solid;
    margin: 1.25rem 0;
}

.news-home:last-child hr {
    display: none;
}

.large-button {
    display: block;
    width: 10rem;
    text-align: center;
    margin: auto;
}

.dash-info a {
    color: #063A2E;
}

.resource-card-management {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.25rem;
}

.password-input {
    display: flex;
    justify-content: space-between;
}

.password-input input {
    font-weight: 400;
}

.justify-between {
    justify-content: space-between;
}

.m-auto {
    margin: auto;
}

.mt-5 {
    margin-top: 1.25rem !important;
}

.mt-10 {
    margin-top: 2.5rem !important;
}

.mt-24 {
    margin-top: 6rem;
}

.mr-5 {
    margin-right: 1.25rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 1.25rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.mb-11 {
    margin-bottom: 2.75rem;
}

.mb-14 {
    margin-bottom: 3.5rem;
}

.mb-18 {
    margin-bottom: 4.75rem !important;
}

.image-wrapper {
    display: flex;
    align-items: flex-end;
}

.image-wrapper input {
    margin-left: 15px;
}

.members-button {
    display: flex;
}

@media (min-width: 768px) {
    header {
        font-weight: normal;
    }

    .section {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .section-col-reverse, .section-col {
        flex-direction: row;
        gap: 4rem;
    }

    .section-img {
        padding-bottom: 0;
    }

    .section-col-end {
        display: flex;
        justify-content: flex-end;
    }

    .side-text {
        font-size: 1.875rem;
        line-height: 2.25rem;
        width: 50%;
    }

    .w-50 {
        width: 50%;
    }

    .w-33 {
        width: 33.333333%;
    }

    .latest-news hr {
        display: none;
    }

    .structure-subtitle {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .filter-wrapper {
        width: 29%;
    }

    .search-wrapper {
        width: 69%;
    }

    .location-svg svg {
        width: 20px;
    }

    .short-desc-svg svg {
        width: 38px;
    }

    .membership-item {
        width: 20%;
    }

    .news-col {
        display: flex;
        flex-direction: row;
    }

    .large-button {
        display: inline;
    }

    .mb-0d {
        margin-bottom: 0;
    }

    .mb-14d {
        margin-bottom: 3.5rem;
    }

}
@media (max-width: 980px) {
    .towncrier-container {
        flex-direction: column;
    }
    .towncrier-content {
        width: auto;
        margin-top: 50px;
    }

}
@media (max-width: 640px) {
    .members-button {
        display: flex;
        flex-direction: column;
    }
    .members-button span {
        margin-bottom: 30px;
    }
    .image-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    .image-wrapper img {
        margin-bottom: 20px;
    }
    .image-wrapper input {
        margin-left: 0;
    }
}