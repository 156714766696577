.heading button {
    color: #063A2E;
    font-weight: 700;
    border-radius: 10px;
    border: 1px #063A2E solid;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    background-color: #fff;
    font-size: 1rem;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    gap: 4rem;
}

.heading svg {
    margin-left: 1rem;
    vertical-align: middle;
}
.headingWrapper {
    max-width: 75%;
}
.link {
    width: 20%;
}


@media (min-width: 768px) {
    .link {
        width: auto;
    }
}
@media (max-width: 980px) {
    .link {
        display: none;
    }
    .headingWrapper {
        max-width: 100%;
    }
}