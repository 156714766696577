.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2.25rem;
}

@media (max-width: 1280px) {
    .grid {
        display: flex;
        flex-direction: column;
    }
}