.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2.25rem;
}

.col:nth-child(5n + 1),
.col:nth-child(5n + 2),
.col:nth-child(5n + 3),
.col:nth-child(5n + 4),
.col:nth-child(5n + 5),
.col:nth-child(5n + 6){
    grid-column: span 4;
}


.col {
    background-color: #EBF0EF;
    border-radius: 10px;
    padding: 1rem;
}

.content {
    display: flex;
    align-items: center;
}


.title {
    color: #063A2E;
    font-size: 0.875rem;
    font-weight: 900;
}

.col a {
    color: #063A2E;
    font-weight: 700;
    border-radius: 10px;
    border: 1px #063A2E solid;
    padding: 0.625rem 1.25rem;

}

.col a:hover {
    color: #fff;
    background-color: #063A2E;
}

.col span {
    display: flex;
    justify-content: center;
}

.type {
    border: 1px #1d4959 solid;
    border-radius: 9999px;
    width: fit-content;
}

.type span {
    padding: 0 1.25rem;
}

.button {
    padding: 0.75rem 2rem;
    color: #fff;
    max-width: 13rem;
    cursor: pointer;
    background: #063A2E;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.125rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border: solid 2px #063A2E;
}

.button:hover {
    background: #fff;
    border: solid 2px #063A2E;
    color: #063A2E !important;
}