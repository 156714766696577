.eventContainer {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2.75rem;
    justify-content: space-between;
}

.card, .cardOffers {
    padding: 1.75rem;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2.25rem;
}

.col:nth-child(5n + 1),
.col:nth-child(5n + 2),
.col:nth-child(5n + 3),
.col:nth-child(5n + 4),
.col:nth-child(5n + 5),
.col:nth-child(5n + 6){
    grid-column: span 4;
}

.col {
    background-color: #EBF0EF;
    border-radius: 10px;
    padding: 1rem;
}

.card:first-child, .eventInfo {
    width: 70%;
}

.card:last-child {
    width: 30%;
}

.eventDate {
    width: 20%;
}

.eventWrapper {
    border-radius: 10px;
    border: 1px #063A2E solid;
    margin-bottom: 1.5rem;
    display: flex;
    padding: 1.25rem;
    align-items: center;
}

.eventWrapper:last-child, .offerWrapper:last-child {
    margin-bottom: 0;
}

.eventWrapper p, .icon, .offerWrapper p {
    margin: 0;
}

.icon {
    margin-bottom: 0 !important;
}

.eventWrapper hr {
    border: solid 1px #000;
    height: 44px;
}

.eventTitle {
    font-weight: 700;
    color: #063A2E;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0.625rem !important;
}

.day {
    color: #063A2E;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
}

.month {
    text-align: center;
}

.discount, .code {
    font-weight: 700;
    color: #063A2E;
}

.offerWrapper {
    border-radius: 10px;
    border: 1px #063A2E solid;
    margin-bottom: 0.625rem;
    padding: 0.75rem;
}

.newsText, .newsDate {
    color: #000;
}

.newsDate {
    margin-bottom: 5px !important;
}

@media (max-width: 980px) {
    .eventContainer {
        flex-direction: column;
    }
    .card {
        width: auto !important;
    }
    .eventContainer:last-child {
        margin-bottom: 0
    }
    .grid {
        display: flex;
        flex-direction: column;
    }
}