.containerProgress {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0 auto;
}

.progressBar {
    margin-right: 4px;
    width: 100%;
}

.progressText {
    min-width: 35px;
}

@media (min-width: 768px) {

}