.iconContentItem {
    display: flex;
    margin-bottom: 1.75rem;
}

.iconContentItem svg {
    margin-right: 0.625rem;
}

.iconContentItem p {
    margin: 0;
}

.iconContentItem a {
    color: #000 !important;
    word-break: break-all;
}