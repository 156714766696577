.heading {
    font-weight: 900;
    font-size: 2rem;
    line-height: 2.25rem;
    color: #063A2E;
    margin: 0;
}

@media (min-width: 768px) {
    .heading {
        font-size: 3rem;
        line-height: 1.375;
    }
}