.heading {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #063A2E;
    margin-bottom: 2rem;
    margin-top: 0;
}

@media (min-width: 768px) {

}