.infoContainer {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2.75rem;
    justify-content: space-between;
}

.card {
    padding: 1.75rem;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 100%;
}
.cardItem {
    display: flex;
    align-items: center;

}

.cardItem svg {
    margin-right: 1.5rem;
}

.link {
    color: #fff;
    width: fit-content;
    font-weight: 600;
    max-width: 13rem;
    padding: 0.75rem 2rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    background: #063A2E;
    font-size: 1rem;
    cursor: pointer;
    border: solid 2px #063A2E;
    border-radius: 0.125rem;
    text-align: center;
}

.link:hover {
    color: #063A2E !important;
    background: #fff;
    border: solid 2px #063A2E;
}
.link:hover a {
    color: #063A2E !important;
}
.infoWrapper, .cardGauges {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.waterLine {
    margin: 15px auto;
    width: 40px;
    background-color: #063a2e;
    height: 3px;
    border: none
}
.cardGauges2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.cardGauges {
    margin-bottom: 20px;
}
@media (max-width: 1280px) {
    .infoWrapper {
        flex-direction: column;
        align-items: flex-start;
    }
    .infoWrapper > div:first-child {
        margin-bottom: 25px;
    }
}
@media (max-width: 980px) {
    .infoContainer, .cardGauges2, .cardGauges {
        flex-direction: column;
    }
    .card {
        width: auto;
    }
    .cardGauges {
        margin-bottom: 0;
    }
}