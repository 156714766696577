.button {
    width: fit-content;
    padding: 0.75rem 2rem;
    color: #fff;
    max-width: 13rem;
    background: #063A2E;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.125rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border: solid 2px #063A2E;
    font-size: 1rem;
    text-align: center;
}

.button:hover {
    background: #fff;
    border: solid 2px #063A2E;
    color: #063A2E !important;
}
.button:hover a {
    color: #063A2E !important;
}
@media (max-width: 640px) {
    .button {
        width: fit-content;
    }
}