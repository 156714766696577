.gauge {
    display: block;
}

.gaugeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gaugeWrapper {
    width: fit-content;
}

.gaugeWrapper:first-child {
    margin-right: 0;
}

.gaugeText {
    margin-left: 10px;
}

.button {
    padding: 0.75rem 2rem;
    margin-bottom: 1rem;
    color: #fff;
    max-width: 13rem;
    background: #063A2E;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.125rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border: solid 2px #063A2E;
    font-size: 1rem;
}

.button:hover {
    background: #fff;
    border: solid 2px #063A2E;
    color: #063A2E !important;
}
@media (max-width: 640px) {
    .button {
        width: fit-content;
    }
}
@media (min-width: 768px) {
    .gauge {
        display: flex;
    }

    .gaugeWrapper:first-child {
        margin-right: 10px;
    }
}